<template>
	<div class="p-1">

		<div v-if="banner">
			<b-link :to="{ name: 'ObserverDetail', params: { observer_id: observer.observer_id } }" class="float-right ml-3">Vedi Missione</b-link>
			<a href="javascript:;" @click.prevent="ux.edit = !ux.edit" class="float-right text-primary">Modifica</a>

			<h4>
				Observer <strong>{{observer.gyu_coin.name}}</strong>
			</h4>

			<ObserverEdit @update="ux.edit = false" :observer="observer" v-if="ux.edit"></ObserverEdit>

			<ObserverMissionMeta :observer="observer"></ObserverMissionMeta>

		</div>

		<div class="mb-1" v-if="observer.active">
			<p class="lead text-center"><b-icon icon="clock"></b-icon> Ultime 24 Ore</p>
			<b-row class="mb-1">
				<b-col>
					<p class="mb-0 text-danger">Minimo <small>({{day.min.dt|date}})</small></p>
					<p class="lead mb-1">
						<Price :price="day.min.close" :digits="8"></Price>
					</p>
				
					<p class="mb-0 text-success">Massimo <small>({{day.max.dt|date}})</small></p>
					<p class="lead mb-1">
						<Price :price="day.max.close" :digits="8"></Price>
					</p>
				</b-col>
				<b-col>
					<p class="mb-0">Volatilità <small>({{observer.gyu_recipe['1d'].volatility_ptc | percent}})</small></p>
					<p class="lead mb-1">
						<Price :price="observer.gyu_recipe['1d'].volatility" :digits="-8"></Price>
					</p>
				
					<p class="mb-0">Oscillazione Prezzo <small>({{observer.gyu_recipe['1d'].range_interval_ptc | percent}})</small></p>
					<p class="lead mb-1">
						<Price :price="observer.gyu_recipe['1d'].range_interval" :digits="-8"></Price>
					</p>
				</b-col>
			</b-row>
		</div>

		<div class="mb-1 mt-3" v-if="observer.active">
			<p class="lead text-center"><b-icon icon="clock-history"></b-icon> Andamenti relativi delle ultime 24 ore</p>

			<b-table small :items="observer.gyu_insight.sequence" :fields="sequence_fields">
				<template #cell(dt)="data">
					{{ data.item.dt | date }}
				</template>
				<template #cell(dir)="data">
					{{ data.item.dir | percent }}
				</template>
				<template #cell(close)="data">
					<Price :price="data.item.close" :digits="8"></Price>
				</template>
				<template #cell(diff)="data">
					<Price :price="data.item.diff" :digits="8"></Price>
				</template>
			</b-table>
		</div>


		<div class="mb-1" v-if="observer.active">
			<p class="lead text-center"><b-icon icon="clock"></b-icon> Ultimi 7 Giorni</p>
			<b-row class="mb-1">
				<b-col>
					<p class="mb-0">Volatilità <small>({{observer.gyu_recipe['7d'].volatility_ptc | percent}})</small></p>
					<p class="lead mb-1">
						<Price :price="observer.gyu_recipe['7d'].volatility" :digits="-8"></Price>
					</p>
				</b-col>
				<b-col>
					<p class="mb-0">Oscillazione Prezzo <small>({{observer.gyu_recipe['7d'].range_interval_ptc | percent}})</small></p>
					<p class="lead mb-1">
						<Price :price="observer.gyu_recipe['7d'].range_interval" :digits="-8"></Price>
					</p>
				</b-col>
			</b-row>
		</div>

		<div v-else>
			<b-button block variant="outline-success" size="lg" :to="{ name: 'ObserverDetail', params: { observer_id: observer.observer_id } }">Vedi Missione</b-button>
		</div>

	</div>

</template>

<script>

import mixins from '@/mixins.js';
import ObserverEdit from '@/components/Observer/edit.vue';
import ObserverMissionMeta from '@/components/Observer/MissionMeta.vue';

export default {
	mixins: [ mixins.coins ],
	components: {
		ObserverEdit,
		ObserverMissionMeta
	},
	props: { 
		observer: { default: false },
		banner: { default: true }
	},
	computed: {
		day: function() {

			var min = _.minBy(this.observer.gyu_insight.sequence, 'close');
			var max = _.maxBy(this.observer.gyu_insight.sequence, 'close');

			return {
				min,
				max
			}
		},
		sequence_fields: function() {
			return [ 
				{ key: 'dt', sortable: true, 'label': 'Data' },
				{ key: 'dir', sortable: false, 'label': 'Direzione' },
				{ key: 'close', sortable: false, 'label': 'Prezzo $' },
				{ key: 'diff', sortable: false, 'label': 'Differenza $' },
			]
		}
	},
	data: function() {
		return {
			datasets: false,
			ux: {
				edit: false
			}
		}
	},
	watch: {
		'observer': {
			deep: true,
			handler: function() {
				this.apiCoinDataset(this.observer.gyu_coin.symbol, this.observer.creation_time, false).then(function(data) {
					this.datasets = data.datasets;
				})
			},
			immediate: true
		}
	}
};

</script>