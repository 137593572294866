<template>
	<div>

		<b-skeleton-wrapper :loading="!coins">

			<template #loading>
				<p class="lead">Attendi Caricamento</p>
				<p>L'operazione potrebbe richiedere più di qualche secondo… e più di qualche MB di dati</p>
				<b-card>
					<b-skeleton width="85%"></b-skeleton>
					<b-skeleton width="55%"></b-skeleton>
					<b-skeleton width="70%"></b-skeleton>
				</b-card>
			</template>

			<div>
				<h5>Tabloid</h5>
				<p class="lead text-muted">
					In questa sezione sono listate tutte le crypto di <strong>CoinBase</strong>, e sono riportati tutti i dati estratti/interpretati/elaborati.<br />
					I dataset di riferimento per le <strong>candles</strong>, <strong>dati live</strong> e <strong>predizione</strong> provengono da <strong>Nomics</strong>
				</p>

				<div v-if="calculation">
					<b-row>
						<b-col>
							<strong>Strategia Mantieni 7gg</strong>
							<hr />
							<pre>{{ calculation.duration }}</pre>
						</b-col>
						<b-col>
							<strong>Strategia Esci al Margine</strong>
							<hr />
							<pre>{{ calculation.margin }}</pre>
						</b-col>
					</b-row>
				</div>

				<!-- <b-btn @click.prevent="calculate">Calculate</b-btn> -->

				<b-row class="mb-4">
					<b-col md="2"></b-col>
					<b-col md="10">

						<div>
							<b-nav tabs>
								<b-nav-item @click.prevent="tab('wayback')" :active="ux.tab == 'wayback'">Previsione del {{ dayInPast }}</b-nav-item>
								<b-nav-item @click.prevent="tab('stats')" :active="ux.tab == 'stats'">Analisi Previsione</b-nav-item>
								<b-nav-item @click.prevent="tab('market')" :active="ux.tab == 'market'">Mercato</b-nav-item>
								<b-nav-item @click.prevent="tab('table')" :active="ux.tab == 'table'">Table</b-nav-item>
								<b-nav-item disabled>Disabled</b-nav-item>
							</b-nav>
						</div>

					</b-col>
				</b-row>

				<div>
					<TabloidCard ref="coin_card" :tab="ux.tab" v-for="coin in the_coins" :coin="coin"></TabloidCard>
				</div>
			</div>			

		</b-skeleton-wrapper>

		
		
	</div>
</template>

<script>

import moment from 'moment'
import TabloidCard from '@/components/Coins/TabloidCard.vue';

export default {
	components: {
		TabloidCard
	},
	data: function() {
		return {
			coins: false,
			ux: {
				calculation: false,
				tab: false
			}
		}
	},
	computed: {
		dayInPast: function() {
			return moment().add(-7, 'days').format('DD/MM')
		},
		calculation: function() {

			if(this.ux.calculation == false) return false;

			var items = _.map(this.$refs.coin_card, 'today_prediction');

			var buyBets = _.filter(items, { direction: 1 });
			var sellBets = _.filter(items, { direction: -1 });

			var wallet_duration = [];
			var wallet_margin = [];

			_.each(buyBets, function(bet) {

				var buyValue = _.random(10,100);

				wallet_duration.push({
					coin: bet.prediction.coin_id,
					buy: buyValue,
					exit: buyValue + ((buyValue/100) * bet.prediction_live)
				});

				if(bet.prediction_live > bet.prediction_ratio) {

					wallet_margin.push({
						coin: bet.prediction.coin_id,
						buy: buyValue,
						exit: buyValue + ((buyValue/100) * bet.prediction_ratio)
					});

				} else {

					wallet_margin.push({
						coin: bet.prediction.coin_id,
						buy: buyValue,
						exit: buyValue + ((buyValue/100) * bet.prediction_live)
					});					

				}


			});

			return {
				duration: {
					exchanges: wallet_duration,
					entryCost: _.sumBy(wallet_duration, 'buy'),
					exitCost: _.sumBy(wallet_duration, 'exit'),
					total: _.sumBy(wallet_duration, 'exit') - _.sumBy(wallet_duration, 'buy')
				},
				margin: {
					exchanges: wallet_margin,
					entryCost: _.sumBy(wallet_margin, 'buy'),
					exitCost: _.sumBy(wallet_margin, 'exit'),
					total: _.sumBy(wallet_margin, 'exit') - _.sumBy(wallet_margin, 'buy')
				}
			};

			return _.map(items, 'today_prediction');

		},
		the_coins: function() {

			return _.orderBy(_.filter(this.coins.coins, function(a) { return a.gyu_datasets.prediction.length > 0; }), [ 'rank', 'rank_delta' ], [ 'asc', 'desc' ]);

		}
	},
	created: function() {
		this.fetch();
	},
	watch: {
		'$route.query.tab': {
			immediate: true,
			deep: true,
			handler: function() {
				if(!this.$route.query.tab)
					this.tab('wayback');
				else
					this.ux.tab = this.$route.query.tab;
			}
		}
	},
	methods: {
		tab: function(tab) {
			if(this.ux.tab != tab) {
				this.$router.push({
					query: {
						tab: tab
					}
				})
			}
		},
		calculate: function() {
			this.ux.calculation = true;
		},
		fetch: function() {

			var symbols = [
				'AAVE',	'ALGO',	'ATOM',	'BAL',	'BAND',	'BAT',	'BCH',	'BNT',	'BSV',	'BTC',	'CGLD',	'COMP',	'CVC',	'DAI',	'DASH',	'DNT',	'EOS',	'ETC',	'ETH',	'FIL',	'GRT',	'KNC',	'LINK',	'LRC',	'LTC',	'MANA',	'MKR',	'NMR',	'NU',	'OMG',	'OXT',	'REN',	'REP',	'SNX',	'USDC',	'UMA',	'UNI',	'WBTC',	'XLM',	'XRP',	'XTZ',	'YFI',	'ZEC',	'ZRX'
			];

			// var symbols = [ 'BTC', 'BNB' ];

			this.$http.post('https://cryptounicorn.chimeralabs.net/api/coins.graph', { symbols }).then(function(data) {
				this.coins = data.data;
			});

		}
	}
};

</script>