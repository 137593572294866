<template>
	<div>
		
		<TrendChart
			:datasets="datasets"
			:grid="labels"
			:labels="labels"
			:gradient="true"
			:interactive="true"
			@mouse-move="focusPoint"
			:min="min">
		</TrendChart>

		<TickCard v-if="focus" :tick="focus" :digits="8"></TickCard>
		<!--
		<div v-if="density">
			<hr />
			<p>Density: <strong>{{ density }}</strong></p>
			<pre>
				{{ datasets_info }}
			</pre>
		</div>-->

	</div>
</template>
<script>

import _ from 'lodash'
import numeral from 'numeral'
import moment from 'moment'

import TickCard from '@/components/Coins/TickCard.vue'

export default {
	props: [ 'candles', 'options', 'density' ],
	components: {
		TickCard
	},
	mounted: function() {
		this.focus = {
			time: _.last(this.candles[0]).date,
			item: _.last(this.candles[0])
		}
	},
	data: function() {
		return {
			focus: false,
			ux: {
				focus: true,
				filter_from: false,
			}
		}
	},
	methods: {
		focusPoint: function(item) {

			if(item) {
				
				var labels = this.labels.raw;
				var payload = {
					time: labels[item.index],
					item: _.find(this.candles[0], { date: labels[item.index] })
				}

				this.$emit('focus', payload);
				this.focus = payload;
				this.ux.focus = true;

			} else {
				this.ux.focus = false;
				this.$emit('unfocus');
			}

		}
	},
	computed: {
		datasets_info: function() {
			return {
				limit_min_data: this.candles[0][0].date,
				limit_max_data: this.candles[0][this.candles[0].length - 1].date,
			}
		},
		grid: function() {
			return {
				verticalLines: true,
				horizontalLines: false
			}
		},
		labels: function() {

			var master_layer = this.datasets[0].labels;

			return {
				xLabels: this.options.hideX == false ? _.map(master_layer, function(d) { return moment(d).format('D/M') }) : [],
				raw: master_layer,
				yLabels: 5,
				yLabelsTextFormatter: function(val) {
					return numeral(parseInt(val) / Math.pow(10,8)).format('0,0.000');
				}
			}
		},
		min: function() {

			var max = _.cloneDeep(this.datasets[0].data).sort().reverse()[0];
			var min = _.cloneDeep(this.datasets[0].data).sort()[0];
			return min - (max-min)/3;

		},
		datasets: function() {

			return _.map(this.candles, function(a) {

				var unit_set = false;
				if(this.density == undefined)
					unit_set = a;
				else {
					unit_set = _.takeRight(a, this.density);
				}

				return {
					labels: _.map(unit_set, 'date'),
					data: _.map(unit_set, 'close'),
					raw: unit_set,
					smooth: true,
					fill: true,
					showPoints: false
				}

			}.bind(this));
		}
	}
};

</script>

<style></style>