<template>
	<div>
		<b-input type="text" placeholder="Cerca la crypto" v-model="search" v-if="!pick"/>

		<div v-if="results">
			<b-form-radio v-model="pick" :value="result.coin_id" v-for="result in results.coins">
				{{ result.symbol }}
				<small class="text-muted">{{ result.name }}</small>
			</b-form-radio>
		</div>
	</div>
</template>

<script>

import _ from 'lodash';
import mixins from '@/mixins.js';

export default {
	mixins: [ mixins.coins ],
	data: function() {
		return {
			pick: false,
			search: '',
			results: false,
			ux: {
				wait: false
			}
		}
	},
	watch: {
		search: {
			handler: _.debounce(function() {

				this.apiCoinsSearch(this.search).then(function(data) {
					this.results = data.data;
				});

			}, 400)
		},
		pick: {
			handler: function() {
				this.$emit('selected', this.pick);
			}
		}
	},
	methods: {
		
	}
};

</script>