<template>
	
	<div>

		<div v-for="order in orders">

			<b-form-group label="Crypto">
				<CoinSelect @selected="order.coin_id = $event"></CoinSelect>
			</b-form-group>

			<b-form-group label="Prezzo di Partenza">
				<PriceDesigner @change="order.price = $event" :digits="8" :starting="0"></PriceDesigner>
			</b-form-group>

			<b-form-group label="Dollari Acquistati">
				<PriceDesigner @change="order.dollars = $event" :digits="8" :starting="0"></PriceDesigner>
			</b-form-group>

			<b-row>
				<b-col>
					<b-form-group label="Data Entrata">
						<b-row>
							<b-col>
								<b-calendar v-model="order.enter.date" locale="it-IT"></b-calendar>
							</b-col>
							<b-col>
								<b-time v-model="order.enter.time" locale="it-IT"></b-time>
							</b-col>
						</b-row>
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group label="Data Uscita">
						<b-row>
							<b-col>
								<b-calendar v-model="order.exit.date" locale="it-IT"></b-calendar>
							</b-col>
							<b-col>
								<b-time v-model="order.exit.time" locale="it-IT"></b-time>
							</b-col>
						</b-row>
					</b-form-group>
				</b-col>
			</b-row>

		</div>

		<b-btn-group>
			<b-btn @click.prevent="importWallet">Importa Wallet</b-btn>
			<b-btn @click.prevent="exportWallet">Esporta Wallet</b-btn>
		</b-btn-group>

		<pre>{{the_orders}}</pre>

		<pre>{{canAddOrder}}</pre>

		<b-btn @click.prevent="addOrder">Add Order</b-btn>
	</div>

</template>

<script>

/*
[{"coin_id":17,"price":4979537000000,"dollars":100000000000,"enter":{"date":"2020-03-25","time":"00:00:00"},"exit":{"date":false,"time":false}},{"coin_id":56,"price":34000000,"dollars":100000000000,"enter":{"date":"2021-02-17","time":false},"exit":{"date":false,"time":false}}]
 */

import moment from 'moment';
import CoinSelect from '@/components/Coins/CoinSelect.vue'
import PriceDesigner from '@/components/Coins/PriceDesigner.vue'
import _ from 'lodash';

export default {
	components: {
		CoinSelect,
		PriceDesigner
	},
	data: function() {
		return {
			orders: []
		}
	},
	watch: {
		orders: {
			deep: true,
			immediate: true,
			handler: function() {
				this.$emit('change', this.the_orders);
			}
		}
	},
	computed: {
		the_orders: function() {
			return _.map(_.cloneDeep(this.orders), function(order) {

				order.amount = order.price == 0 ? 0 : ( order.dollars/order.price );

				order.date_enter = order.enter.date + ' ' + (order.enter.time ? order.enter.time : '');
				delete order.enter;

				if(order.exit.date != false)
					order.date_exit = order.exit.date + ' ' + (order.exit.time ? order.exit.time : '');
				else
					order.date_exit = false;

				delete order.exit;
				return order;

			});
		},
		canAddOrder: function() {
			return _.filter(this.orders, function(i) {
				if(i.coin_id == false) return true;
				return false;
			}).length == 0;
		}
	},
	methods: {
		importWallet: function() {
			var content = prompt("Incolla qui il wallet.");
			if(content) {

				var w = JSON.parse(content);
				this.orders = w;

			}
		},
		exportWallet: function() {

			console.log(JSON.stringify(this.orders));

		},
		addOrder: function() {
			if(!this.canAddOrder) return false;
			this.orders.push({
				coin_id: false,
				price: false,
				dollars: false,
				enter: { date: false, time: false },
				exit: { date: false, time: false }
			});
		}
	}
};

</script>



