<template>
	<div>
		<b-row>
			<b-col>
				<strong class="small">Time Stamp</strong>
				<p>{{ tick.time }}</p>
			</b-col>
			<b-col>
				<strong class="small">Open</strong>
				<div>
					<Price :bits="false" :price="tick.item.open" :digits="tick.item.digits" :price-date="tick.item.price_date" />
				</div>
			</b-col>
			<b-col>
				<strong class="small">Close</strong>
				<div>
					<Price :bits="false" :price="tick.item.close" :digits="tick.item.digits" :price-date="tick.item.price_date" />
				</div>
			</b-col>
			<b-col>
				<b-row>
					<b-col>
						<strong class="small">Low</strong>
						<div>
							<Price :bits="false" :price="tick.item.low" :digits="tick.item.digits" :price-date="tick.item.price_date" />
						</div>
					</b-col>
					<b-col>
						<strong class="small">High</strong>
						<div>
							<Price :bits="false" :price="tick.item.high" :digits="tick.item.digits" :price-date="tick.item.price_date" />
						</div>
					</b-col>
				</b-row>
			</b-col>
		</b-row>
	</div>
</template>

<script>

export default {
	props: [ 'tick' ],
	computed: {
		color: function() {

		}
	}
};

</script>