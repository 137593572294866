<template>
	<div v-if="coin">

		<b-row class="mb-2">
			<b-col>
				<b-card header="Ultimi 7gg Giornaliero">
					<CoinChart :candles="[ coin.coin.gyu_datasets.candles_days ]" :options="{ hideX: false }"></CoinChart>
				</b-card>
			</b-col>
			<b-col>
				<b-card header="Ultimi 7gg Orario">

					<template slot="header">
						<b-row>
							<b-col>Tick Orario</b-col>
							<b-col>nav</b-col>
						</b-row>
					</template>

					<CoinChart :candles="[ coin.coin.gyu_datasets.candles_hours ]" :options="{ hideX: true }" :density="24"></CoinChart>
				</b-card>
			</b-col>
		</b-row>

		<div class="mt-2" v-show="false">
			<b-row>
				<b-col>
					<pre>{{ coin.coin.gyu_telemetry.direction.days }}</pre>
				</b-col>
				<b-col>
					<pre>{{ coin.coin.gyu_telemetry.direction.hours }}</pre>
				</b-col>
			</b-row>
		</div>

		<b-table responsive small striped hover :items="coin.coin.gyu_datasets.candles_days" :fields="[ 'date', 'open', 'high', 'low', 'close', 'volume' ]">
			
			<template #cell(open)="data">
				<Price :price="data.item.open" :digits="data.item.digits" :price-date="data.item.date" />
			</template>

			<template #cell(close)="data">
				<Price :price="data.item.close" :digits="data.item.digits" :price-date="data.item.date" />
			</template>

			<template #cell(high)="data">
				<Price :price="data.item.high" :digits="data.item.digits" :price-date="data.item.date" />
			</template>

			<template #cell(low)="data">
				<Price :price="data.item.low" :digits="data.item.digits" :price-date="data.item.date" />
			</template>

		</b-table>
		<hr />

		<b-table responsive small striped hover :items="coin.coin.gyu_datasets.candles_hours" :fields="[ 'date', 'open', 'high', 'low', 'close', 'volume' ]">
			
			<template #cell(open)="data">
				<Price :price="data.item.open" :digits="data.item.digits" :price-date="data.item.date" />
			</template>

			<template #cell(close)="data">
				<Price :price="data.item.close" :digits="data.item.digits" :price-date="data.item.date" />
			</template>

			<template #cell(high)="data">
				<Price :price="data.item.high" :digits="data.item.digits" :price-date="data.item.date" />
			</template>

			<template #cell(low)="data">
				<Price :price="data.item.low" :digits="data.item.digits" :price-date="data.item.date" />
			</template>

		</b-table>
		<hr />

		<pre>{{coin}}</pre>
	</div>
</template>

<script>
	
import mixins from '@/mixins.js'
import CoinChart from '@/components/Coins/CandleGraph.vue'

export default {
	mixins: [ mixins.coins ],
	components: {
		CoinChart
	},
	data: function() {
		return {
			tick_orario_payload: false,
			tick_giorno_payload: false,
			coin: false
		}
	},
	watch: {
		'$route.params.symbol': {
			deep: true,
			immediate: true,
			handler: function() {
				this.apiCoinsGet(this.$route.params.symbol, [ 'Frontend', 'Telemetry' ]).then(function(data) {
					this.coin = data.data;
				})
			}
		}
	}
}

</script>