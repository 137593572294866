<template>
	
	<div>
		
		<b-popover :target="popOverRimbalzoHelp" triggers="hover" placement="top">
			<template #title>Rimbalzo</template>
			
			Il <strong>Rimbalzo</strong> è calcolato in base alla direzione della previsione.
			<ul class="list-unstyled mt-2 mb-2">
				<li>Se la tendenza della previsione è a ridurre l'errore <strong>la percentuale di rimbalzo sarà bassa</strong>. E la <strong>previsione per il futuro più attendibile</strong>.</li>
				<li>Se la tendenza della previsione non è lineare, e l'errore subisce variazioni, <strong>la percentuale di rimbalzo sarà alta.</strong></li>
			</ul>
			Il rimbalzo non fornisce un'indicazione sulla previsione; se l'errore sulla previsione è basso, è un indice di inaffidabilità del target, ma non della bontà della previsione.
		</b-popover>

		<b-row class="mb-5">
			<b-col md="2">
				<h5>{{ coin.symbol }}</h5>
				<p class="text-muted text-truncate">{{coin.name}}</p>
				
				<div>
					<b-badge variant="success" v-if="today_prediction.direction == 1">7gg fa: Acquista</b-badge>
					<b-badge variant="danger" v-else="today_prediction.direction == -1">7gg fa: Vendi</b-badge>
				</div>
				<div>
					<b-badge variant="success" v-if="right_now_prediction.direction == 1">ORA: Acquista</b-badge>
					<b-badge variant="danger" v-else="right_now_prediction.direction == -1">ORA: Vendi</b-badge>
				</div>
				<hr />
				<p class="lead">{{ coin.price | lazy | usd }}</p>
				<hr />
				Posizione Nomics: #{{ coin.rank }}
				<span v-if="coin.rank_delta != 0">({{coin.rank_delta}})</span>
			</b-col>
			<b-col md="10" v-if="tab == 'wayback'">
				<div v-if="today_prediction">

					<b-popover :target="popOverCoinTargetScored" triggers="hover" placement="top">
						<template #title><b-icon icon="check-circle-fill"></b-icon> Target Centrato</template>
						
						Inizio Previsione <small>(-7d)</small>: <Price :bits="false" :price="today_prediction.prediction.price_start" :digits="8"></Price><br />
						Attuale: <Price :bits="false" :price="coin.gyu_datasets.live.price" :digits="8"></Price> ({{ today_prediction.prediction_live | chunk }} %)<br />
						
						<span class="text-muted">Previsto: <Price :bits="false" :price="today_prediction.prediction.price_end" :digits="8"></Price> ({{ today_prediction.prediction_ratio | chunk }}%)</span>

					</b-popover>

					<b-row>
						<b-col md="4">

							<div v-if="today_prediction.correct" class="text-success">
								<span :id="popOverCoinTargetScored"><b-icon icon="check-circle-fill"></b-icon> Target Centrato</span>
							</div>
							<div v-else>
								<div><b-icon icon="x-circle-fill"></b-icon> Target <strong>non</strong> centrato</div>
								<div class="text-success" v-if="today_prediction.good"><b-icon icon="check-circle-fill"></b-icon> Direzione Individuata</div>
								<div class="text-danger" v-else="today_prediction.good"><b-icon icon="exclamation-circle-fill"></b-icon> Direzione Errata</div>
							</div>

							<div class="mt-2">
								<!--<code>Corretta: <strong>{{today_prediction.correct}}</strong></code><br />
								<code>Direzione Corretta: <strong>{{today_prediction.good}}</strong></code><br />
								<hr />-->
								Inizio Previsione <small>(-7d)</small>: <Price :bits="false" :price="today_prediction.prediction.price_start" :digits="8"></Price><br />
								Previsto: <Price :bits="false" :price="today_prediction.prediction.price_end" :digits="8"></Price> ({{ today_prediction.prediction_ratio | chunk }}%)<br />
								Attuale: <Price :bits="false" :price="coin.gyu_datasets.live.price" :digits="8"></Price> ({{ today_prediction.prediction_live | chunk }} %)
							</div>
						</b-col>
						<b-col>
							
							<div class="pb-2">
								<TrendChart
									:datasets="wayback_prevision_graph.datasets"
									:labels="wayback_prevision_graph.labels"
									:grid="wayback_prevision_graph.grid"
									:interactive="true"
									:min="wayback_prevision_graph.min"
									>
								</TrendChart>
							</div>

							<!-- <pre>{{ wayback_prevision_graph.min }}</pre> -->

						</b-col>
					</b-row>

					<b-row class="mt-4" v-if="today_prediction.direction == 1">
						<b-col v-for="target in wayback_opportunity.durations">
							<span :class="{ 'text-success': target.marked == true }">Target <strong>{{ target.ratio | chunk }}%</strong></span>
							<div v-if="target.marked">
								<p class="small text-muted">{{target.duration}}</p>
							</div>
						</b-col>
					</b-row>

				</div>
			</b-col>
			<b-col md="10" v-if="tab == 'stats'">
				
				
				<div>
					<b-card class="mb-2">
						<template slot="header">
							<b-icon class="text-info" icon="graph-up"></b-icon> Previsione + 
							Realtà <b-icon class="text-danger" icon="graph-up"></b-icon>
						</template>
						<TrendChart
							:datasets="stats_graph.datasets"
							:labels="stats_graph.labels"
							:grid="stats_graph.grid"
							:interactive="true"
							>
						</TrendChart>
					</b-card>
				</div>
				<div>

					<b-card class="mb-2">

						<template slot="header">Grafico dell'errore <em>in %</em></template>
						<TrendChart
							:datasets="stats_graph_error.datasets"
							:labels="stats_graph_error.labels"
							:grid="stats_graph_error.grid"
							:max="1"
							:min="0"
							:interactive="true"
							>
						</TrendChart>

					</b-card>

					<b-row class="mb-2 mx-2">
						<b-col>
							<p class="small text-muted mb-0">Errore nei 30 giorni</p>
							<p>{{ coin.gyu_stats.prediction_error * 100 | chunk }} %</p>
						</b-col>
						<b-col>
							<p class="small text-muted mb-0">Errore nei 4 giorni</p>
							<p>{{ coin.gyu_stats.prediction_error_latest_4 * 100 | chunk }} %</p>
						</b-col>
						<b-col>
							<p class="small text-muted mb-0">Errore Ieri</p>
							<p>{{ coin.gyu_stats.prediction_error_yesterday * 100 | chunk }} %</p>
						</b-col>
						<b-col>
							<p class="small text-muted mb-0">Errore Live</p>
							<p>{{ coin.gyu_stats.prediction_error_live * 100 | chunk }} %</p>
						</b-col>
						<b-col md="1">
							<p class="small text-muted mb-0">Errore</p>
							<p><small>{{ coin.gyu_stats.prediction_error_live * 100 | chunk }}</small></p>
						</b-col>
					</b-row>

					<b-card>
						<template slot="header">Rimbalzo</template>

						<b-row>
							<b-col :md="9">
								<TrendChart height="90px"
									:datasets="[{ data: coin.gyu_stats.bouncing_raw }]"
									:labels="{ yLabels: 3 }"
									:grid="stats_graph_error.grid"
									:min="-1"
									:max="1"
									:interactive="true"
									>
								</TrendChart>
							</b-col>
							<b-col>
								
								<p class="small text-muted mb-0">Rimbalzo a 30 giorni <b-icon icon="patch-question" :id="popOverRimbalzoHelp"></b-icon></p>
								<p>{{ coin.gyu_stats.bouncing * 100 | chunk }}</p>
								<p class="small text-muted mb-0">Rimbalzo a 4 giorni</p>
								<p>{{ coin.gyu_stats.bouncing_latest_4 * 100 | chunk }}</p>

							</b-col>
						</b-row>
					</b-card>


				</div>

			</b-col>
			<b-col md="10" v-if="tab == 'table'">
				<b-row>
					<b-col v-for="k in [ '7d', '1d', '4h', 'live' ]" md="4" class="mb-2">
						<b-card>
							<template slot="header">
								<span v-if="k != 'live'">{{ k }}</span>
								<span v-else>
									<b-icon icon="circle-fill"></b-icon> Live {{ coin.gyu_table_table.table[k].timestamp }}
								</span>
							</template>

							<b-row>
								<b-col md="5">
									<span class="text-muted">Volatilità</span>
								</b-col>
								<b-col>
									<span :class="coin.gyu_table_table.table[k].range_interval_ptc > 0 ? 'text-success':'text-danger'">{{ coin.gyu_table_table.table[k].volatility | usd }} </span>
									<small class="text-muted">{{ coin.gyu_table_table.table[k].volatility_ptc | ptc }}</small>
								</b-col>
							</b-row>

							<b-row class="mt-2">
								<b-col md="5">
									<span class="text-muted">Differenza</span>
								</b-col>
								<b-col>
									<span :class="coin.gyu_table_table.table[k].range_interval_ptc > 0 ? 'text-success':'text-danger'">{{ coin.gyu_table_table.table[k].range_interval | usd(true) }} </span>
									<small class="text-muted">{{ coin.gyu_table_table.table[k].range_interval_ptc | ptc }}</small>
								</b-col>
							</b-row>

							<b-row class="mt-3">
								<b-col md="6">
									<div class="text-muted">Minimo Atteso</div>
									<p><b-icon class="small" v-if="coin.gyu_table_table.table[k].min < coin.gyu_table_table.table[k].expected.min" icon="check-circle-fill"></b-icon> {{ coin.gyu_table_table.table[k].expected.min | usd }}</p>
								</b-col>
								<b-col md="6">
									<div class="text-muted">Massimo Atteso</div>
									<p><b-icon class="small" v-if="coin.gyu_table_table.table[k].max > coin.gyu_table_table.table[k].expected.max" icon="check-circle-fill"></b-icon> {{ coin.gyu_table_table.table[k].expected.max | usd }}</p>
								</b-col>
							</b-row>

						</b-card>
					</b-col>
					<b-col md="8">

						<b-card>
							<template slot="header">Previsioni</template>
							<pre>{{ coin.gyu_table_table.table.live }}</pre>
						</b-card>

					</b-col>

				</b-row>

				<!-- <pre>{{ coin.gyu_table_table }}</pre> -->
			</b-col>
			<b-col md="10" v-if="tab == 'market'">
				
				<div>
					<b-card class="mb-2">
						<template slot="header">Prezzo</template>
						<TrendChart
							:datasets="stats_graph_market.datasets_price"
							:max="stats_graph_market.max_price"
							:min="stats_graph_market.min_price"
							
							:labels="stats_graph_market.labels_price"
							:grid="stats_graph_market.grid"
							:interactive="true"
							>
						</TrendChart>
					</b-card>
					<b-card>
						<template slot="header">Volume</template>
						<TrendChart
							:datasets="stats_graph_market.datasets_volume"
							:max="stats_graph_market.max_volume"
							:min="stats_graph_market.min_volume"

							:labels="stats_graph_market.labels_volume"
							:grid="stats_graph_market.grid"
							:interactive="true"
							>
						</TrendChart>
					</b-card>
				</div>


			</b-col>
		</b-row>

	</div>

</template>

<script>

import moment from 'moment';
import numeral from 'numeral';

export default {
	props: {
		coin: { default: false },
		tab: { default: 'wayback' }
	},
	filters: {
		lazy: function(b) {
			return b/Math.pow(10,8);
		},
		ptc: function(a) {
			return numeral(a).format('0.00%');
		},
		usd: function(a,plus = false) {

			return numeral(a).format((plus ? '+' : '') + '0,000.00 $')

		},
		chunk: function(v) {

			var amount = v.toFixed(2);
			return amount;

		}
	},
	computed: {
		popOverRimbalzoHelp: function() {
			return 'pop-' + this.coin.symbol + '-rimbaloz';
		},
		popOverCoinTargetScored: function() {
			return 'pop-' + this.coin.symbol + '-scored';
		},
		week_past_candles: function() {

			var ds = _.filter(this.coin.gyu_datasets.candles_days, function(a) {

				if(moment(a.date).isAfter(moment())) return false;
				if(moment(a.date).isBefore(moment().add('-7', 'days'))) return false;
				return true;

			});

			return ds;

		},
		stats_graph_market: function() {

			return {
				datasets_volume: [ { data: this.coin.gyu_market.volumes.days.graph, showPoints: true } ],
				min_volume: _.min(this.coin.gyu_market.volumes.days.graph) - (_.min(this.coin.gyu_market.volumes.days.graph) * 0.3),
				max_volume: _.max(this.coin.gyu_market.volumes.days.graph) + (_.max(this.coin.gyu_market.volumes.days.graph) * 0.3),
				labels_volume: {
					xLabels: this.coin.gyu_market.day_l,
					yLabels: 3,
					yLabelsTextFormatter: function(v) {
						return numeral(v).format('0a')
					}
				},

				datasets_price: [ { data: this.coin.gyu_market.day_v, showPoints: true } ],
				min_price: _.min(this.coin.gyu_market.day_v) - (_.min(this.coin.gyu_market.day_v) * 0.01),
				max_price: _.max(this.coin.gyu_market.day_v) + (_.max(this.coin.gyu_market.day_v) * 0.01),
				labels_price: {
					xLabels: this.coin.gyu_market.day_l,
					yLabels: 3,
					yLabelsTextFormatter: function(v) {
						return numeral(v/Math.pow(10,8)).format('0.0000a')
					}
				},

				grid: { 
					verticalLines: true,
					horizontalLines: true
				},
			}

		},
		stats_graph: function() {

			var ds = this.coin.gyu_stats.prediction;

			var labels = {
				xLabels: _.map(_.map(ds, 'date'), function(a) { return moment(a).format('D') }),
				yLabels: 0,
				yLabelsTextFormatter: function(val) {
					return numeral(parseInt(val) / Math.pow(10,8)).format('0,0.000');
				}
			};

			var datasets = [];

			datasets.push({
				data: _.map(ds, 'predicted_ratio'),
				smooth: false,
				fill: false,
				stroke: true,
				className: 'pr',
				showPoints: false
			});

			datasets.push({
				data: _.map(ds, 'actual_ratio'),
				smooth: false,
				fill: false,
				stroke: true,
				className: 'ar',
				showPoints: false
			});

			var grid = {
				verticalLines: true,
				horizontalLines: true
			}


			return {
				datasets,
				labels,
				grid,
				min: -10,
				errors: _.map(ds, 'error')
			}

		},
		stats_graph_error: function() {

			var ds = this.coin.gyu_stats.prediction_reshaped;
			var bs = this.coin.gyu_stats.prediction;

			var labels = {
				xLabels: _.map(_.map(bs, 'date'), function(a) { return moment(a).format('D') }),
				yLabels: 3,
				yLabelsTextFormatter: function(val) {
					return '';
				}
			};

			var datasets = [];
			datasets.push({
				data: ds,
				smooth: false,
				fill: true,
				stroke: true,
				className: 'er',
				showPoints: false
			});
			/*
			var total = _.sum(_.map(ds, 'error'));
			if(total) {
				var bs = _.map(ds, function(a) {
					a.error = Math.abs(100 * (a.error / total));
					return a;
				});
			}

			if(bs != undefined) {
				
				datasets.push({
					data: _.map(bs, 'error'),
					smooth: true,
					fill: true,
					stroke: true,
					className: 'er',
					showPoints: false
				});

			} else {
				console.log(this.coin.symbol);
				console.log(_.map(ds, 'error'));
				console.log(_.sumBy(_.map(ds, 'error')));
			}*/


			var grid = {
				horizontalLines: true
			}


			return {
				datasets,
				labels,
				grid,
				min: -1,
				errors: _.map(ds, 'error')
			}

		},
		wayback_opportunity: function() {
			
			var ds = this.week_past_candles;

			var bestScenario = _.maxBy(ds, 'high');
			var worstScenario = _.minBy(ds, 'low');

			var startPrice = _.find(this.coin.gyu_datasets.prediction, { date_start: ds[0].date }).price_start;
			var bestRatio = startPrice/bestScenario.close;
			
			var durations = _.map([ 1, 2.5, 3, 5 ], function(a) {

				var priceToBeReachedIfRatio = startPrice + ((startPrice/100) * a);
				var isPriceReached = _.find(ds, function(p) {
					return p.close >= priceToBeReachedIfRatio;
				}.bind(this));

				return {
					ratio: a,
					mark_price: priceToBeReachedIfRatio,
					marked: isPriceReached ? true : false,
					duration: isPriceReached ? isPriceReached.date : false
				}

			});

			durations.push({
				ratio: (100/startPrice) *(bestScenario.close - startPrice),
				mark_price: bestScenario.close,
				marked: true,
				duration: bestScenario.date
			})

			return {
				startPrice,
				bestRatio,
				durations,
				bestScenario,
				worstScenario
			}

			return ds;

		},
		wayback_prevision_graph: function() {

			var ds = _.filter(this.coin.gyu_datasets.prediction, function(a) {

				if(moment(a.date_end).isAfter(moment())) return false;
				if(moment(a.date_end).isBefore(moment().add('-8', 'days'))) return false;
				return true;

			});

			ds = _.map(ds, function(a) {

				if(moment(a.date_end).isSame(moment().format('YYYY-MM-DD'))) {
					a.price_actual = this.coin.gyu_datasets.live.price;
				} else if(a.price_actual == 0) {
					var tick = _.find(this.coin.gyu_datasets.candles_days, { date: moment(a.date_end).format('YYYY-MM-DD') });
					a.price_actual = tick.close;
				}

				return a;

			}.bind(this));

			var labels = {
				xLabels: _.map(_.map(ds, 'date_end'), function(a) { return moment(a).format('D') }),
				yLabels: 5,
				yLabelsTextFormatter: function(val) {
					return numeral(parseInt(val) / Math.pow(10,8)).format('0,0.000');
				}
			};

			var datasets = [];

			datasets.push({
				data: _.map(ds, 'price_actual'),
				smooth: true,
				fill: true,
				showPoints: false
			});

			datasets.push({
				data: _.map(ds, 'price_end'),
				smooth: true,
				fill: false,
				showPoints: false
			});

			var grid = {
				verticalLines: true,
				horizontalLines: true
			}

			var min = _.minBy(_.filter(ds, function(a) { return a.price_actual != 0 }), 'price_start').price_start

			return {
				datasets,
				labels,
				grid,
				min
			}


		},
		right_now_prediction: function() {

			var today = moment().format('YYYY-MM-DD');
			var prediction = _.find(this.coin.gyu_datasets.prediction, { date_start: today });
			var direction = false;
			var prediction_ratio = false;

			if(!prediction) return false;

			prediction_ratio = 100 * ((prediction.price_end - prediction.price_start)/prediction.price_start);

			if(prediction.price_start < prediction.price_end)
				direction = 1;
			else
				direction = -1;

			return {
				direction,
				prediction_ratio,
				prediction,
			}

			return prediction;

		},
		today_prediction: function() {

			var today = moment().format('YYYY-MM-DD');
			var prediction = _.find(this.coin.gyu_datasets.prediction, { date_end: today });

			if(!prediction) return false;
			var live = this.coin.gyu_datasets.live;

			var direction = false;
			var correct = false;
			var good = false;
			var over = false;
			var prediction_ratio = false;
			var prediction_live = false;

			if(prediction.price_start < prediction.price_end)
				direction = 1;
			else
				direction = -1;
			
			if(direction == 1) {

				if(live.price >= prediction.price_end)
					correct = true;

				if(live.price > prediction.price_start)
					good = true;

				/*
				1200
				1400 (200) = 6
				100/6 = 16,666

				1200/100 =  
				 */

				prediction_ratio = 100 * ((prediction.price_end - prediction.price_start)/prediction.price_start);
				prediction_live = 100 * ((live.price - prediction.price_start)/prediction.price_start)


			} else {

				if(live.price <= prediction.price_end)
					correct = true;

				if(live.price < prediction.price_start)
					good = true;

				prediction_ratio = 100 * ((prediction.price_end - prediction.price_start)/prediction.price_start);
				prediction_live = 100 * ((live.price - prediction.price_start)/prediction.price_start)

			}

			return {
				correct,
				good,
				direction,
				prediction_ratio,
				prediction_live,
				prediction,
			}

			return prediction;

		}
	}
};

</script>

<style>
	
.pr .stroke {
    stroke: #17a2b8;
    stroke-width: 1;
}

.ar .stroke {
    stroke: #dc3545;
    stroke-width: 1;
}

.er .stroke {
    stroke: #28a745;
    stroke-width: 2;
}

</style>