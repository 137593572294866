<template>
	
	<b-card class="my-3">
		<template slot="header">Modifica Observer</template>	
		<template slot="footer">
			<b-btn class="mt-1" @click.prevent="saveEdits" outline variant="success" size="sm">Salva</b-btn>
		</template>	
		<b-form>
			<b-form-group label="Prezzo di Partenza">
				<PriceDesigner @change="observer.price_start = $event" :digits="8" :starting="observer.price_start"></PriceDesigner>
			</b-form-group>

			<b-form-group label="Idea">
				<b-select v-model="observer.target">
					<b-select-option value="none">Incerta</b-select-option>
					<b-select-option value="up">Crescita</b-select-option>
					<b-select-option value="down">Decrescita</b-select-option>
				</b-select>
			</b-form-group>

			<b-form-group label="Durata (in giorni)">
				<b-input type="number" v-model="observer.duration" />
			</b-form-group>

		</b-form>

	</b-card>

</template>

<script>

import mixins from '@/mixins.js';
import PriceDesigner from '@/components/Coins/PriceDesigner.vue'

export default {
	mixins: [ mixins.observers ],
	components: {
		PriceDesigner
	},
	props: {
		observer: { default: false }
	},
	data: function() {
		return {
			ux: {
				wait: false
			}
		}
	},
	methods: {
		saveEdits: function() {

			this.ux.wait = true;
			this.apiObserverUpdate(this.observer.observer_id, this.observer).then(function(data) {
				this.ux.wait = false;
				this.$emit('update');
			})

		}
	}
};

</script>