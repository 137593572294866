<template>
	<div>

		<b-row class="mb-1">
			<b-col>
				<p class="mb-0">Inizio Missione</p>
				<p class="lead">{{ observer.creation_time | date }}</p>
			</b-col>
			<b-col>
				<p class="mb-0">Durata Missione</p>
				<p class="lead">
					<span v-if="observer.active == 1">{{ observer.gyu_insight.days }}<span class="text-secondary">/{{ observer.duration }}gg</span></span>
					<span v-else>{{ observer.duration }}</span>
				</p>
			</b-col>
			<b-col>
				<p class="mb-0">Idea</p>
				<p class="lead">
					{{ observer.target }}
				</p>
			</b-col>
			<b-col v-if="observer.active == 1">
				<p class="mb-0"><b-icon icon="circle-fill" class="text-danger small"></b-icon> Live</p>
				<p class="lead">
					<strong>{{ observer.gyu_insight.perc > 0 ? 'up' : 'down' }}</strong>
					{{ observer.gyu_insight.perc }}%
				</p>
			</b-col>
			<b-col v-else>
				<p class="mb-0">Esito</p>
				<p class="lead">
					<strong>{{ observer.gyu_insight.perc > 0 ? 'up' : 'down' }}</strong>
					{{ observer.gyu_insight.perc }}%
				</p>
			</b-col>
		</b-row>

		<b-row class="mb-1">
			<b-col>
				<p class="mb-0">Prezzo di Partenza</p>
				<p class="lead">
					<Price :price="observer.price_start" :digits="observer.gyu_coin.digits"></Price>
				</p>
			</b-col>
			<b-col v-if="observer.active">
				<p class="mb-0">Prezzo ultima chiusura</p>
				<p class="lead">
					<Price :price="observer.gyu_recipe['1h'].close" :digits="-8"></Price>
				</p>
			</b-col>
			<b-col>
				<p class="mb-0" v-if="observer.active">Prezzo <b-icon icon="circle-fill" class="text-danger small"></b-icon> Live</p>
				<p class="mb-0" v-else>Prezzo Fine Missione</p>

				<p class="lead">
					<Price :price="observer.gyu_recipe.live.close" :digits="-8"></Price>
				</p>
			</b-col>
		</b-row>


	</div>
</template>

<script>

export default {

	props: {
		observer: { default: false }
	}

};

</script>