<template>
	
	<div class="p-1">
		<h4>
			Nuovo Observer
		</h4>

		<p class="lead" v-if="!observer.coin_id">
			Per poter aggiungere un observer, devi prima trovare la crypto da osservare. <span class="text-muted">Tutti i prezzi sono in <strong>USD</strong></span>
		</p>

		<b-form>

			<CoinSelect @selected="observer.coin_id = $event"></CoinSelect>

			<div v-if="observer.coin_id" class="mt-2">

				<b-form-group label="Prezzo di Partenza">
					<!-- <b-input type="number" v-model="observer.price_start" /> -->
					<PriceDesigner v-if="meta_coin" @change="observer.price_start = $event" :digits="meta_coin.coin.digits" :starting="meta_coin.coin.price"></PriceDesigner>
				</b-form-group>

				<b-form-group label="Idea">
					<b-select v-model="observer.target">
						<b-select-option value="none">Incerta</b-select-option>
						<b-select-option value="up">Crescita</b-select-option>
						<b-select-option value="down">Decrescita</b-select-option>
					</b-select>
				</b-form-group>

				<b-form-group label="Durata (in giorni)">
					<b-input type="number" v-model="observer.duration" />
				</b-form-group>
			</div>

			<b-btn class="mt-1" :disabled="!canCreate" @click.prevent="createObserver" outline variant="success" size="sm">Salva</b-btn>
		
		</b-form>

		<p class="lead mt-3" v-if="canCreate" v-html="verbose">{{ verbose }}</p>

	</div>

</template>

<script>

import _ from 'lodash';
import mixins from '@/mixins.js';
import CoinSelect from '@/components/Coins/CoinSelect.vue'
import PriceDesigner from '@/components/Coins/PriceDesigner.vue'

export default {
	mixins: [ mixins.observers, mixins.coins ],
	components: {
		CoinSelect,
		PriceDesigner
	},
	watch: {
		//meta_coin: {},
		'observer.coin_id': {
			handler: function() {
				this.apiCoinsGetId(this.observer.coin_id).then(function(data) {
					this.meta_coin = data.data;
				});
			}
		}
	},
	computed: {
		verbose: function() {

			return this.$t( 'observers.creationVerbose.' + this.observer.target, { 
				duration: this.observer.duration, 
				coin: this.meta_coin.coin.name, 
				price: _.round(this.observer.price_start/Math.pow(10,8), 2)
			});

		},
		canCreate: function() {

			if(this.observer.coin_id == false) return false;
			if(this.observer.price_start == false) return false;

			return true;
		}
	},
	data: function() {
		return {
			meta_coin: false,
			observer: {
				coin_id: false,
				price_start: false,
				target: 'none',
				duration: 7
			},
			ux: {
				wait: false
			}
		}
	},
	methods: {
		createObserver: function() {

			this.ux.wait = true;
			this.apiObserverCreate(this.observer).then(function(data) {
				this.ux.wait = false;
				this.$emit('close');
				console.log(data);
			})

		}
	}
};

</script>