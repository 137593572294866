<template>
	
	<div>
		<b-input v-model="current" v-currency="{currency: 'USD', locale: 'en', precision: 8}" />
	</div>

</template>

<script>

export default {
	props: {
		digits: { default: 8 },
		starting: { default: 0 } 
	},
	data: function() {
		return {
			current: 0
		}
	},
	created: function() {
		this.current = this.starting / Math.pow(10, this.digits);
	},
	watch: {
		current: function() {
			this.$emit('change', this.current * Math.pow(10, this.digits));
		}
	}
};

</script>