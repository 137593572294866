<template>
	<div v-if="data_observers">
		<b-row>
			<b-col md="8">

				<b-table small borderless striped responsive hover :fields="fields" :items="observers">

					<template #cell(price_start)="data">
						<Price :price="data.item.price_start" :digits="8"></Price>
					</template>

					<template #cell(duration)="data">
						<span v-if="data.item.active == 1">
							<span v-if="data.item.gyu_insight.days">{{ data.item.gyu_insight.days }}</span><small class="text-muted d-none d-md-inline">/{{ data.item.duration }}gg</small>
						</span>
						<span v-else>
							{{ data.item.duration }}
						</span>
					</template>

					<template #cell(target)="data">
						<span v-if="data.item.target == 'up'"><span class="d-inline d-sm-none">🔺</span><span class="d-none d-md-inline">Crescita</span></span>
						<span v-if="data.item.target == 'down'"><span class="d-inline d-sm-none">🔻</span><span class="d-none d-md-inline">Decrescita</span></span>
						<span v-if="data.item.target == 'none'"><span class="d-inline d-sm-none"></span><span class="d-none d-md-inline">Incerta</span></span>
					</template>

					<template #cell(creation_time)="data">
						<Date :date="data.item.creation_time" />
					</template>

					<template #cell(gyu_insight.perc)="data">
						<span :class="{ 'text-muted':data.item.active == 0 }" v-if="data.item.gyu_insight.sequence">{{ data.item.gyu_insight.perc }} %</span>
						<span v-else><b-icon icon="clock"></b-icon></span>
					</template>

					<template #cell(gyu_coin.symbol)="data">
						<a v-if="data.item.gyu_insight.sequence" href="javascript:;" @click.prevent="detail = data.item">{{ data.item.gyu_coin.symbol }}</a>
						<span v-else>{{ data.item.gyu_coin.symbol }}</span>
					</template>

				</b-table>
			</b-col>
			<b-col md="4">

				<b-btn @click.prevent="newObserver = true" block variant="outline-success"><span class="small"><b-icon icon="plus-circle-fill" /></span> {{ $t('observers.buttonCreate') }}</b-btn>
				
				<!-- <pre>{{env.user}}</pre> -->

				<p class="mt-5"><b-link class="text-muted" :to="{ name: 'ProductsObserver' }">{{ $t('observers.moreInfos') }}</b-link></p>
				<!-- <pre>{{ data_observers }}</pre> -->
			</b-col>
		</b-row>

		<!-- <WalletDesigner @change="walletSimulator = $event"></WalletDesigner> - ->

		<AnalysisWallet :wallet="walletSimulator"></AnalysisWallet>
		-->

		<b-sidebar width="610px" :visible="detail != false" @hidden="detail = false" right shadow>
			<div class="px-3">
				<Observer :observer="detail" v-if="detail"></Observer>
			</div>
		</b-sidebar>

		<b-sidebar width="610px" :visible="newObserver != false" @hidden="newObserver = false" right shadow>
			<div class="px-3">
				<ObserverNew v-if="newObserver" @close="newObserver = false"></ObserverNew>
			</div>
		</b-sidebar>

	</div>
</template>

<script>

import mixins from '@/mixins.js';
import Observer from '@/components/Observer/view.vue'
import ObserverNew from '@/components/Observer/new.vue'
import WalletDesigner from '@/components/Wallets/Designer.vue'
import AnalysisWallet from '@/components/Analysis/Wallet.vue'

export default {
	components: {
		Observer,
		ObserverNew,
		WalletDesigner,
		AnalysisWallet,
	},
	mixins: [ mixins.observers ],
	data: function() {
		return {
			data_observers: false,
			detail: false,
			newObserver: false,
			walletSimulator: [
				  {
				    "coin_id": 17,
				    "price": 940819660514,
				    "dollars": 100000000000,
				    "amount": 100000000000/940819660514,
				    "date_enter": "2020-01-10 00:00:00",
				    "date_exit": "2020-03-19 00:00:00"
				  },
				  {
				    "coin_id": 17,
				    "price": 4842680496008,
				    "dollars": 100000000000,
				    "amount": 100000000000/4842680496008,
				    "date_enter": "2021-02-15 00:00:00",
				    "date_exit": "2021-02-20 00:00:00"
				  },
				  {
				    "coin_id": 56,
				    "price": 34000000,
				    "dollars": 100000000000,
				    "amount": 2941.176470588235,
				    "date_enter": "2021-02-15 ",
				    "date_exit": "2021-02-25 ",
				  }
			]
		}
	},
	computed: {
		fields: function() {
			return [
				{
					key: 'gyu_coin.symbol',
					label: 'SYM'
				},
				{
					key: 'creation_time',
					label: 'Inizio Oss.'
				},
				{
					key: 'duration',
					label: 'D'
				},
				{
					key: 'target',
					label: 'Idea'
				},
				{
					key: 'price_start',
					label: 'Prezzo Part.'
				},
				{
					key: 'gyu_insight.perc',
					label: 'Attuale'
				},
				{
					key: 'none',
					label: ''
				}
			];
		},
		observers: function() {
			return this.data_observers.observers;
		}
	},
	methods: {
		fetch: function() {
			this.apiObserverGets(['insight']).then(function(data) {
				this.data_observers = data.data;
			});
		}
	},
	watch: {
		newObserver: function() {
			if(this.newObserver == false) this.fetch();
		}
	},
	created: function() {
		this.fetch();
	}
};

</script>