<template>
	<div v-if="data_observer">

		<b-btn @click.prevent="fetch()">Refresh</b-btn>
		<pre>{{ observer.gyu_features }}</pre>

		<b-row>
			<b-col md="7">
				<h4>
					Observer <strong>{{observer.gyu_coin.name}}</strong>
				</h4>
				<ObserverMissionMeta :observer="observer"></ObserverMissionMeta>
				<hr />

				<h5>Orari di attività</h5>
				<b-row>
					<b-col>
						<p>Migliori orari per l'<strong>acquisto</strong></p>
						<b-table small striped hover :items="distribution_low">
							<template #cell(amount)="data">
								<b-icon class="mr-1" icon="star" v-for="i in data.item.amount"/>
							</template>
						</b-table>
					</b-col>
					<b-col>
						<p>Migliori orari per la <strong>vendita</strong></p>
						<b-table small striped hover :items="distribution_high">
							<template #cell(amount)="data">
								<b-icon class="mr-1" icon="star" v-for="i in data.item.amount"/>
							</template>
						</b-table>
					</b-col>
				</b-row>

				<hr />
				<b-card size="sm">
					<b-row>
						<b-col>
							<p class="m-0">Calcolo composto sull'Average</p>
							<p class="lead mb-0">{{ composto }}</p>
						</b-col>
						<b-col class="text-muted" md="7">
							<p class="small mb-0">
								Il calcolo è il risultato di una strategia <strong>perfetta</strong> con uno scambio al giorno con la media della giornata; partendo da <em>1</em><br />
								<strong>È intraguardabile</strong> ma rappresenta il potenziale della moneta.
							</p>
						</b-col>
					</b-row>
				</b-card>
				<hr />
				<h5>Oscillazione Giornaliera Min-Max</h5>
				<p>In tabella sono riportate, giorno per giorno, le finestre d'occasione per l'entrata e per l'uscita.</p>

				<b-table small striped hover :items="real_oscillation">
					<template #cell(average)="data">
						{{ data.item.average | percent }}
					</template>

					<template #cell(rounds)="data">
						<div v-for="r in data.item.rounds">
							<code>{{ r.min_at | time }} - {{ r.max_at | time }} <strong>{{ r.difference_ptc | percent }}</strong></code>
						</div>
					</template>
				</b-table>

			</b-col>
			<b-col>
				<Observer :observer="observer" :banner="false">
					
				</Observer>
			</b-col>
		</b-row>

		<pre>{{observer}}</pre>
	</div>
</template>

<script>

import mixins from '@/mixins.js';
import moment from 'moment';
import Observer from '@/components/Observer/view.vue'
import ObserverNew from '@/components/Observer/new.vue'
import ObserverMissionMeta from '@/components/Observer/MissionMeta.vue'
import WalletDesigner from '@/components/Wallets/Designer.vue'
import AnalysisWallet from '@/components/Analysis/Wallet.vue'

export default {
	components: {
		Observer,
		ObserverNew,
		WalletDesigner,
		AnalysisWallet,
		ObserverMissionMeta
	},
	mixins: [ mixins.observers ],
	data: function() {
		return {
			data_observer: false
		}
	},
	computed: {
		composto: function() {

			var starting = 1;

			_.each(this.real_oscillation, function(i) {
				if(i.average)
					starting += starting * i.average
			});

			return starting;

		},
		real_oscillation: function() {
			var ds = this.observer.gyu_coin.gyu_datasets.candles_hours;

			var filtered = _.filter(ds, function(i) {
				return moment(i.date).isAfter(moment(this.observer.creation_time));
			}.bind(this));

			var grouped_by_day = _.groupBy(filtered, function(i) {
				return moment(i.date).format('YYYY-MM-DD');
			});

			// Now.. day by day.. i need to really create an array of oscillations! :)
			var o = [];
			for(var b in grouped_by_day) {

				var round = [];
				var rounds = [];

				var prev = false;
				var blocked = false;

				_.each(grouped_by_day[b], function(item) {
					if(item.close < prev) {
						rounds.push(round);
						round = [];
					}
					
					round.push(item);
					prev = item.close;

				});

				var rounds_fixed = _.orderBy(_.filter(_.map(rounds, function(i) {
					return {
						duration: i.length,
						min_price: _.minBy(i, 'close').close,
						min_at: _.minBy(i, 'close').date,
						max_price: _.maxBy(i, 'close').close,
						max_at: _.maxBy(i, 'close').date,
						difference: _.maxBy(i, 'close').close - _.minBy(i, 'close').close,
						difference_ptc: (_.maxBy(i, 'close').close / _.minBy(i, 'close').close) - 1
					}
				}), function(a) { return a.duration > 1 }), [ 'difference' ], [ 'desc' ]);

				o.push({
					day: b,
					rounds: rounds_fixed,
					average: _.meanBy(rounds_fixed, 'difference_ptc'),
					occasions: rounds_fixed.length
				})

			}

			return o;

		},
		distribution_low: function() {

			var b = [];
			_.each(this.observer.gyu_features.time_distribution.hours.low, function(i,k) {
				b.push({ hour: k, amount: i })
			});
			return _.orderBy(b, [ 'amount' ], ['desc']);

		},
		distribution_high: function() {

			var b = [];
			_.each(this.observer.gyu_features.time_distribution.hours.high, function(i,k) {
				b.push({ hour: k, amount: i })
			});
			return _.orderBy(b, [ 'amount' ], [ 'desc' ]);

		},
		observer: function() {
			return this.data_observer.observer;
		}
	},
	methods: {
		fetch: function() {
			this.apiObserverGet(this.$route.params.observer_id, ['coin', 'features', 'insight']).then(function(data) {
				this.data_observer = data.data;
			});
		}
	},
	watch: {
		'$route.params.observer_id': {
			deep: true,
			handler: function() {
				this.fetch();
			},
			immediate: true
		}
	}
};

</script>