<template>
	
	<div>
		<code>{{ ux.wait }}</code>
		<b-btn @click.prevent="analyze">a</b-btn>
		<b-card>
			<template slot="header">
				Analisi Wallet
			</template>
			<pre>{{ analysis }}</pre>
		</b-card>
	</div>

</template>

<script>

import mixins from '@/mixins.js';

export default {
	mixins: [ mixins.analysis ],
	data: function() {
		return {
			analysis: false,
			ux: {
				wait: false
			}
		}
	},
	props: {
		wallet: { default: false }
	},
	watch: {
		wallet: {
			deep: true,
			handler: function() {

				if(this.wallet != false) {
					this.analyze();
				}

			},
			immediate: true
		}
	},
	methods: {
		analyze: function() {

			this.ux.wait = true;
			this.apiAnalysisWallet(this.wallet).then(function(data) {
				this.analysis = data.data;
				this.ux.wait = false;
			})

		}
	}
};

</script>