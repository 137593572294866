<template>
	<div>
		
		<b-row>
			<b-col md="7">
				<h4>Registrati<small>, è gratis!</small></h4>
			</b-col>
			<b-col md="5">

				<h5>Login</h5>
				<b-form>
					
					<b-form-group label="Email" v-if="ux.confirm == false">
						<b-input v-model="email" type="email" placeholder="il tuo indirizzo email" />
					</b-form-group>

					<b-form-group label="Inserisci il codice di verifica" v-else>
						<b-input v-model="trust" type="text" placeholder="Inserisci il codice di verifica" />
					</b-form-group>

					<b-button :disabled="ux.confirm == false" class="mt-2" variant="success" outline @click.prevent="login">Login</b-button>

				</b-form>

			</b-col>
		</b-row>

	</div>
</template>

<script>

import mixins from '@/mixins.js'
	
export default {
	mixins: [ mixins.account ],
	data: function() {
		return {
			ux: {
				confirm: false
			},
			session_id: false,
			email: '',
			trust: ''
		}
	},
	computed: {
		redirect: function() {
			if(this.$route.query.redirectTo == undefined) return false;
			return this.$route.query.redirectTo;	
		}
	},
	methods: {
		login: function() {
			this.apiAccountTrust(this.session_id, this.trust).then(function(data) {

				this.unicorn.s('jwt', data.data.session);
				this.unicorn.s('bearer', data.data.bearer);
				this.$root.LoadEnv(function() {

					if(this.redirect) {
						this.$router.push(this.redirect);
					} else
						this.$router.push( { name: 'MyAccount' } );

				}.bind(this));

			});
		},
		searchUser: function() {

			this.apiAccountLogin(this.email).then(function(data) {
				this.ux.confirm = true;
				this.session_id = data.data.users_session_id;
			});

		}
	},
	watch: {
		email: {
			handler: _.debounce(function() {

				this.searchUser();

			}, 500)
		}
	}
};

</script>