var api = 'https://cryptounicorn.chimeralabs.net/api/';

export default {
	coins: {
		methods: {
			apiCoinsGetId: function(coin_id, contexts) {
				return this.$http.post(api + 'coins.get', { coin_id, contexts });
			},
			apiCoinsGet: function(symbol, contexts) {
				return this.$http.post(api + 'coins.get', { symbol, contexts });
			},
			apiCoinDataset: function(symbol, from, to) {
				return this.$http.post(api + 'coins.dataset', { symbol, from, to });
			},
			apiCoinsSearch: function(symbol) {
				return this.$http.post(api + 'coins.search', { q: symbol });
			}
		}
	},
	account: {
		methods: {
			apiAccountLogin: function(email) {
				return this.$http.post(api + 'users.authenticate', { email });
			},
			apiAccountTrust: function(users_session_id, code) {
				return this.$http.post(api + 'users.trust', { users_session_id, code });
			}
		}
	},
	observers: {
		methods: {
			apiObserverGets: function(contexts = []) {
				return this.$http.post(api + 'observers.gets', { contexts });
			},
			apiObserverUpdate: function(observer_id, data) {
				return this.$http.post(api + 'observers.update', { observer_id, observer: data });
			},
			apiObserverCreate: function(observer) {
				return this.$http.post(api + 'observers.create', { observer });
			},
			apiObserverGet: function(observer_id, contexts = []) {
				return this.$http.post(api + 'observers.get', { observer_id, contexts });
			}
		}
	},
	analysis: {
		methods: {
			apiAnalysisWallet: function(wallet) {
				return this.$http.post(api + 'analysis.wallet', { wallet });
			}
		}
	}
}